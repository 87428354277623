import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./HNTrack.module.scss";
import HNNavBar from "./HNNavBar.jsx";
import { Helmet } from "react-helmet";
import Footer from "../home/Footer.jsx";
import { bindActionCreators } from "redux";
import { setUser } from "../../actions/user";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import GoogleSignIn from "../GoogleSignIn";
import { FiTrendingUp, FiMail, FiLayers } from "react-icons/fi";
import HNList from "./HNList";
import { getWrapper } from "../../helpers/fetch";

const mapStateToProps = (state) => ({
    user: state.user,
    fullState: state,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ setUser }, dispatch);

class HNTrackContainer extends Component {
  constructor() {
    super();
    this.state = {
      dateString: "2023-12-25",
    };
  }

    componentDidMount() {
        // lets refresh user on mount
        this.refreshUser();
    }

    async refreshUser() {
        const { setUser, user } = this.props;
        if (user === {}) return;
        let userInfo = await getWrapper("GET", "/api/refreshUser", {
            Authorization: user.token,
        });
        setUser(userInfo);
    }

    renderHelmet() {
        return (
            <Helmet>
                <title>Hacker News Release Dashboard</title>
                <meta
                    name="description"
                    content="Release, track and measure a Hacker News post or release"
                />
                <meta
                    name="keywords"
                    content="Hacker news, comements, release management, software engineer"
                />
                <meta property="og:url" content="https://www.failflow.com/hnrd" />
                <meta property="og:title" content="Hacker News Release Dashboard" />
                <meta
                    property="og:description"
                    content="Release, track and measure a Hacker News post or release"
                />
                <meta property="og:image" content="https://i.imgur.com/nWR8btF.png" />
                <meta
                    property="og:image:url"
                    content="https://i.imgur.com/nWR8btF.png"
                />
                <meta
                    property="twitter:image"
                    content="https://i.imgur.com/nWR8btF.png"
                />
            </Helmet>
        );
    }

    logout() {
        this.props.logOut();
    }

    renderSignedIn() {
        return (
            <div className={styles.container}>
                <div>
                    <HNList></HNList>
                </div>
            </div>
        );
    }

    renderSplashSection() {
        return (
            <>
                <div className={styles.splashHeader}>HN Release Dashboard</div>
                <div className={styles.splashSecondary}>
                    Be ahead of the conversation and manage a successful release on Hacker
                    News
                </div>
                <div className={styles.loginContainer}>
                    <div className={styles.googleloginContainer}>
                        <GoogleSignIn label="Get Started"></GoogleSignIn>
                    </div>
                    <div className={styles.buttonHelpText}>
                        Sign in to track your first post
                    </div>
                </div>
                <div className={styles.videoIntroContainer}>

                    <img src="https://i.imgur.com/kGN3gCd.png"
                        alt="splash"></img>

                </div>
            </>
        );
    }

    renderSections() {
        return (
            <>
                <div className={styles.greySection}>
                    <div className={styles.sectionHeader}>
                        <span className={styles.color1}>High-level metrics. </span>
                        Get relevant information to track the conversation.
                    </div>
                    <div className={styles.sideBySide}>
                        <div className={styles.sectionImageContainerFull}>
                            <img
                                className={styles.sectionImage}
                                src="https://i.imgur.com/e7j5N4m.png"
                                alt="stats for post"
                            ></img>
                        </div>
                    </div>

                    <div className={styles.sectionHeader}>
                        <span className={styles.color2}>Mission Control. </span>
                        Follow all relevant posts, all in one place.
                    </div>
                    <div className={styles.sideBySide}>
                        <div className={styles.sectionImageContainerFull}>
                            <img
                                className={styles.sectionImage}
                                src="https://i.imgur.com/W8PgBPV.png"
                                alt="sample hnrd list"
                            ></img>
                        </div>
                    </div>
                    <div className={styles.sectionHeader}>
                        <span className={styles.color3}>More to come! </span>
                        More analytics, digest and aggregation.{" "}
                    </div>
                    <div className={styles.flex3}>
                        <div className={styles.futureBox}>
                            <FiTrendingUp
                                className={`${styles.sectionIcon} ${styles.color3}`}
                            />
                            <div className={styles.futureHeader}>Time Series Data</div>
                            <div></div>
                            <div className={styles.futureDescription}>
                                See existing conversation metrics over time and see new metrics,
                                like sub-thread analytics
                            </div>
                        </div>
                        <div className={styles.futureBox}>
                            <div>
                                <FiMail className={`${styles.sectionIcon} ${styles.color3}`} />
                            </div>
                            <div className={styles.futureHeader}>Digest Management</div>
                            <div>
                                Send notifications in regular intervals to be aware of the
                                conversation, but not drowning in it
                            </div>
                        </div>
                        <div className={styles.futureBox}>
                            <div>
                                <FiLayers
                                    className={`${styles.sectionIcon} ${styles.color3}`}
                                />
                            </div>
                            <div className={styles.futureHeader}>Aggregagtion</div>

                            <div>
                                Why stop at HN? Aggregate all the engagement in one view, like
                                Product Hunt and IndieHackers and more!
                            </div>
                        </div>
                    </div>

                    <div className={styles.sectionHeader}>
                        <span className={styles.color4}>Get started. </span>
                        Track your first post.
                    </div>
                </div>
            </>
        );
    }

    renderSignedOut() {
        return (
            <div className={styles.fullContainer}>
                {this.renderSplashSection()}
                {this.renderSections()}

                <div className={styles.loginContainerBottom}>
                    <div className={styles.googleloginContainer}>
                        <GoogleSignIn label="Get Started" big></GoogleSignIn>
                    </div>
                </div>
                <div className={styles.bottomImageContainer}>
                    <img
                        className={styles.bottomImage}
                        src="https://i.imgur.com/djWAckr.png"
                        alt="walking in woods"
                    ></img>
                </div>
            </div>
        );
    }

    render() {
        const { user } = this.props;

        return (
            <div className={styles.all}>
                {this.renderHelmet()}
                <HNNavBar />
                {!isEmpty(user) ? this.renderSignedIn() : this.renderSignedOut()}
                <Footer></Footer>
            </div>
        );
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(HNTrackContainer)
);
