import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { setToken, setUser, logOut } from "../../actions/user";
import { connect } from "react-redux";
import styles from "./Footer.module.scss";

const mapStateToProps = (state) => ({
  user: state.user,
  fullState: state,
});

const mapDispatchToProps = (dispatch) =>
  // okay we need to put the token in the state
  // so we can send it to authorized responses
  bindActionCreators({ setToken, setUser, logOut }, dispatch);

class NavBar extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    // placeholder
  }

  render() {
    return (
      <div className={styles.footerContainer}>
        <div className={styles.copyright}> COPYRIGHT ©2023 FailFlow</div>
        <div className={styles.copyright}>Made with care in NYC</div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
