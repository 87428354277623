import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./DaysInput.module.scss";
import NavBar from "../home/NavBar.jsx";
import { Helmet } from "react-helmet";
import Footer from "../home/Footer.jsx";
import moment from "moment";

class DaysUntil extends Component {
  constructor() {
    super();
    this.state = {
      dateString: "2023-12-25",
      daysLeft: 1,
      humanRep: "December 25, 2023"
    };
  }

  componentDidMount() {
    if (this.props.match.params.days) {
      const dateString = this.props.match.params.days;
      this.setState({ dateString });
      let humanRep = moment.utc(dateString).format("MMMM D, YYYY");

      let now = moment().utc();
      let daysLeft = moment.utc(dateString).diff(now, "days");
      daysLeft += 1;

      this.setState({ daysLeft, humanRep });
    }
  }

  copyLink() {
    /* Get the text field */
    var copyText = document.getElementById("shareLink");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
  }

  renderdaysUntil() {
    const { humanRep } = this.state;
    return (
      <div className={styles.enterDateContainer}>
        <div className={styles.entryContent}>
          <div className={styles.header}>There are</div>
          <div className={styles.daysNumer}>
            {this.state.daysLeft.toLocaleString("en")}
          </div>
          <div className={styles.subHeader}>days until</div>
          <div className={styles.subHeader}> {humanRep}</div>
          <div className={styles.helpText}>
            This page will recalcalculate with every visit, feel free to
            bookmark or share the link below.
          </div>
          <div>
            <input
              className={styles.copyLinkInput}
              value={window.location}
              id="shareLink"
              readOnly
              onClick={() => this.copyLink()}
            ></input>
            <div className={styles.copyButton} onClick={() => this.copyLink()}>
              Copy Link
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>How many days until</title>
        <meta
          name="description"
          content="Calculate how many more days until a certain date"
        />
        <meta
          name="keywords"
          content="days until, days till, days til, calculate days"
        />
        <meta property="og:url" content="https://www.failflow.com/daysuntil" />
        <meta property="og:title" content="How many days until you die?" />
        <meta
          property="og:description"
          content="Calculate how many more days until a certain date"
        />
        <meta property="og:image" content="https://i.imgur.com/EUaEsBG.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/EUaEsBG.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/EUaEsBG.png"
        />
      </Helmet>
    );
  }

  render() {
    return (
      <div>
        {this.renderHelmet()}
        <NavBar />
        {this.renderdaysUntil()}
        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(DaysUntil);
